<template>
  <a href="https://vk.com/kosoplechev" target="_blanc">
    <svg class="ks-socials__icon--svg" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.5466 5.51719C25.72 4.93125 25.5466 4.5 24.7122 4.5H21.9513C21.2481 4.5 20.9247 4.87031 20.7513 5.28281C20.7513 5.28281 19.345 8.70937 17.3575 10.9312C16.7153 11.5734 16.42 11.7797 16.0685 11.7797C15.895 11.7797 15.6278 11.5734 15.6278 10.9875V5.51719C15.6278 4.81406 15.431 4.5 14.8497 4.5H10.5091C10.0685 4.5 9.80596 4.82812 9.80596 5.13281C9.80596 5.79844 10.7997 5.95312 10.9028 7.82812V11.8969C10.9028 12.7875 10.7435 12.9516 10.3919 12.9516C9.45439 12.9516 7.17627 9.51094 5.82627 5.57344C5.5544 4.80938 5.28721 4.5 4.57939 4.5H1.81846C1.03096 4.5 0.871582 4.87031 0.871582 5.28281C0.871582 6.01406 1.80908 9.64688 5.23564 14.4469C7.51846 17.7234 10.7341 19.5 13.6591 19.5C15.4169 19.5 15.6325 19.1063 15.6325 18.4266C15.6325 15.2953 15.4731 15 16.3544 15C16.7622 15 17.4653 15.2063 19.106 16.7859C20.981 18.6609 21.2903 19.5 22.3403 19.5H25.1013C25.8888 19.5 26.2872 19.1062 26.0575 18.3281C25.5325 16.6922 21.9841 13.3266 21.8247 13.1016C21.4169 12.5766 21.5341 12.3422 21.8247 11.8734C21.8294 11.8688 25.1997 7.125 25.5466 5.51719Z"
      />
    </svg>
  </a>
</template>

<script>
export default {}
</script>

<style>
.ks-socials__icon--svg {
  cursor: pointer;
  width: calc(3rem + 3px);
  height: 3rem;
  fill: black;
}
.ks-socials__icon--svg {
  transition: 0.4s ease;
}
.ks-socials__icon--svg:hover {
  fill: var(--color-primary);
  transform: translateY(-4px);
}
</style>
