<template>
  <a href="https://t.me/kosoplechev" target="_blanc">
    <svg class="ks-socials__icon--svg" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4394 4.62225L19.2706 19.566C19.0314 20.6205 18.4081 20.883 17.5224 20.3865L12.6939 16.8285L10.3644 19.0695C10.1064 19.3275 9.89114 19.5428 9.39389 19.5428L9.74114 14.6258L18.6894 6.54C19.0786 6.1935 18.6046 6.00075 18.0849 6.348L7.02239 13.314L2.25989 11.823C1.22414 11.4998 1.20539 10.7873 2.47589 10.29L21.1036 3.11325C21.9661 2.79 22.7206 3.30525 22.4394 4.623V4.62225Z"
      />
    </svg>
  </a>
</template>

<script>
export default {}
</script>

<style>
.ks-socials__icon--svg {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  fill: black;
}
.ks-socials__icon--svg {
  transition: 0.4s ease;
}
.ks-socials__icon--svg:hover {
  fill: var(--color-primary);
  transform: translateY(-4px);
}
</style>
