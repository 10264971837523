<template>
  <div class="bio-column" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
    <div class="ks-bio__chapter">
      <p class="ks-bio__text">
        Я знал кем стану ещё в школе. Благодаря урокам профориентации выбор специальности при поступлении в университет для меня
        не был трудностью: я хотел работать с людьми, быть менеджером.
      </p>
    </div>
    <div class="ks-bio__chapter">
      <p class="ks-bio__text">
        Во временя учёбы на специалитете меня пригласили работать на киностудию. Долго это не продлилось, так как выше
        реквизитора на площадке пробиться не удалось, но опыт, полученный за те годы — бесценный. Я работал с профессионалами
        своего дела, участвовал в сложнейшем процессе: съёмке кино.
      </p>
    </div>
  </div>
  <div class="bio-column" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
    <div class="ks-bio__chapter">
      <p class="ks-bio__text">
        Преддипломную практику я проходил в ВЗАО "АСЭН" — компания, проводившая комплекс работ по строительству энергетических
        объектов. Именно здесь я работал следующие 5 лет, занимая должность Начальника информационно-аналитического отдела.
        Несмотря на то, что работа в основе была технической, я научился отстаивать своё мнение, что в дальнейшем очень
        пригодилось.
      </p>
    </div>
    <div class="ks-bio__chapter">
      <p class="ks-bio__text">
        В 2010-ом произошли 2 события серьёзно изменивших мою жизнь — родилась моя старшая дочь и я впервые официально связал
        свои отношения с РГСУ.
      </p>
    </div>
    <div class="ks-bio__chapter">
      <p class="ks-bio__text">
        В 2020-ом мне предложили занять должность директора Колледжа РГСУ. С того момента многое изменилось.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
/* СТИЛИ ТЕКСТА КРАТКОЙ БИОГРАФИИ */

.ks-bio__chapter {
  width: 100%;
  margin-top: 2rem;
}
.ks-bio__text {
  font-size: 2rem;
  line-height: 3rem;
}
</style>
