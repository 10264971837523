<template>
  <svg class="ks-menu__item--link" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.35 8.5V14.35H1.65V3.4H7.5H7.65V3.25V2.25V2.1H7.5H0.5H0.35V2.25V15.5V15.65H0.5H13.5H13.65V15.5V8.5V8.35H13.5H12.5H12.35V8.5Z"
      stroke-width="0.3"
    />
    <path
      d="M9.50002 0.35H9.35002V0.5V1.5V1.65H9.50002H13.4308L5.79042 9.2904L5.68435 9.39647L5.79042 9.50253L6.49748 10.2096L6.60355 10.3157L6.70961 10.2096L14.35 2.56922V6.5V6.65H14.5H15.5H15.65V6.5V0.5V0.35H15.5H9.50002Z"
      stroke-width="0.3"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style>
.ks-menu__item--link {
  fill: currentColor;
  stroke: currentColor;
  margin-left: 8px;
}
</style>
