<template>
  <div class="ks-hobbie__icons-item">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.9997 62.7999L59.7997 64.5999L60.1997 64.9999C60.1997 64.9999 60.1997 65.1999 59.9997 65.1999C58.5997 67.1999 56.5997 70.3999 56.9997 74.3999C57.3997 78.3999 58.3997 81.1999 60.1997 83.1999C61.5998 84.5999 63.1997 85.1999 64.5997 85.3999C64.3997 85.3999 64.1997 85.5999 63.9997 85.5999C60.3997 86.9999 55.5997 88.9999 53.7997 94.5999C53.1997 96.5999 52.7998 98.9999 52.3997 101.4C51.5998 106.2 50.5997 111.6 47.7997 114.4C43.7997 118.4 38.9997 120.2 33.5997 119.4C28.3997 118.6 22.5998 115.6 17.7997 110.6C12.7997 105.6 9.79975 100 8.99975 94.7999C8.19975 89.3999 9.99975 84.5999 13.9998 80.5999C16.7998 77.7999 20.7998 76.3999 27.1998 75.9999C38.9998 75.1999 41.7997 68.1999 43.5997 63.7999C44.9997 60.1999 45.3997 58.9999 48.9997 58.7999C52.3997 58.1999 54.9997 59.7999 57.9997 62.7999ZM60.9997 59.5999C57.7997 56.3999 53.9997 53.3999 48.1997 53.9999C34.7997 54.9999 43.5998 70.1999 26.5998 71.3999C20.3997 71.7999 14.7997 73.1999 10.5998 77.3999C-0.20025 87.9999 3.19975 102.6 14.3997 113.6C25.5998 124.8 40.1997 128.2 50.7997 117.4C56.1997 112 56.1997 102 57.9997 95.7999C60.3997 88 72.9997 90.5999 72.3997 81.9999C72.3997 81.1999 72.1998 80.5999 71.7998 80.3999C70.1998 78.7999 65.7997 82.5999 63.1997 79.9999C62.1997 78.9999 61.5998 77.1999 61.1997 73.9999C60.7997 69.1999 66.7997 65.5999 64.5997 63.3999C63.3997 62.1999 62.1997 60.9999 60.9997 59.5999Z"
        fill="#B3ADAB"
      />
      <path
        d="M52.2 101.4C52.6 99 53 96.8 53.6 94.6C55.4 89 60.2 87 63.8 85.6C64 85.6 64.2 85.4 64.4 85.4C63 85.2 61.4 84.6 60 83.2C58.2 81.4 57.2 78.6 56.8 74.4C56.4 70.4 58.6 67.4 59.8 65.2C59.8 65.2 59.8 65 60 65L59.6 64.6L57.8 62.8C54.8 59.8 52.4 58.2 48.4 58.4C45 58.6 44.4 59.8 43 63.4C41.2 68 38.6 74.8 26.6 75.6C20.2 76 16.2 77.4 13.4 80.2C9.39997 84.2 7.59997 89 8.39997 94.4C9.19997 99.6 12.2 105.4 17.2 110.2C22.2 115.2 27.8 118.2 33 119C38.4 119.8 43.2 118 47.2 114C50.4 111.4 51.4 106 52.2 101.4Z"
        fill="#D4CECC"
      />
      <path d="M39.6201 78.4461L44.5681 73.4941L54.4701 83.3921L49.5221 88.3421L39.6201 78.4461Z" fill="#76706E" />
      <path d="M30.0439 88.0603L34.9959 83.1104L44.8939 93.0104L39.9419 97.9604L30.0439 88.0603Z" fill="#76706E" />
      <path d="M24.084 96.3563L26.912 93.5283L34.69 101.308L31.86 104.136L24.084 96.3563Z" fill="#76706E" />
      <path d="M57.5996 79.4002L48.5996 70.4002L99.5996 22.2002L105.8 28.4002L57.5996 79.4002Z" fill="#76706E" />
      <path
        d="M45.1996 112C47.7401 112 49.7996 109.941 49.7996 107.4C49.7996 104.86 47.7401 102.8 45.1996 102.8C42.6591 102.8 40.5996 104.86 40.5996 107.4C40.5996 109.941 42.6591 112 45.1996 112Z"
        fill="#76706E"
      />
      <path
        d="M34.1996 116.6C36.1878 116.6 37.7996 114.989 37.7996 113C37.7996 111.012 36.1878 109.4 34.1996 109.4C32.2114 109.4 30.5996 111.012 30.5996 113C30.5996 114.989 32.2114 116.6 34.1996 116.6Z"
        fill="#76706E"
      />
      <path
        d="M105.2 38.4002C103.8 39.8002 103.2 41.0002 104.2 42.2002C105.4 43.4002 106.6 42.6002 108 41.2002C109.4 39.8002 110 38.6002 109 37.4002C108 36.4002 106.6 37.2002 105.2 38.4002Z"
        fill="#76706E"
      />
      <path d="M102.34 36.954L103.752 35.54L106.864 38.652L105.45 40.066L102.34 36.954Z" fill="#76706E" />
      <path
        d="M112.2 31.6004C110.8 33.0004 110.2 34.2004 111.2 35.4004C112.4 36.6004 113.6 35.8004 115 34.4004C116.4 33.0004 117 31.8004 116 30.6004C114.8 29.6004 113.4 30.4004 112.2 31.6004Z"
        fill="#76706E"
      />
      <path d="M109.36 30.0483L110.776 28.6343L113.886 31.7443L112.472 33.1583L109.36 30.0483Z" fill="#76706E" />
      <path
        d="M119 24.8003C117.6 26.2003 117 27.4003 118 28.6003C119.2 29.8003 120.4 29.0003 121.8 27.6003C123.2 26.2003 123.8 25.0003 122.8 23.8003C121.6 22.6003 120.4 23.4003 119 24.8003Z"
        fill="#76706E"
      />
      <path d="M116.1 23.1401L117.514 21.7261L120.626 24.8381L119.21 26.2521L116.1 23.1401Z" fill="#76706E" />
      <path
        d="M89.6003 22.8005C88.2003 24.2005 87.0003 24.8005 85.8003 23.8005C84.6003 22.6005 85.4003 21.4005 86.8003 20.0005C88.2003 18.6005 89.4003 18.0005 90.6003 19.0005C91.6003 20.0005 90.8003 21.4005 89.6003 22.8005Z"
        fill="#76706E"
      />
      <path d="M87.9375 22.5502L89.3535 21.1382L92.4615 24.2522L91.0455 25.6642L87.9375 22.5502Z" fill="#76706E" />
      <path
        d="M96.4001 15.8005C95.0001 17.2005 93.8001 17.8005 92.6001 16.8005C91.4001 15.6005 92.2001 14.4005 93.6001 13.0005C95.0001 11.6005 96.2001 11.0005 97.4001 12.0005C98.4001 13.2005 97.6001 14.6005 96.4001 15.8005Z"
        fill="#76706E"
      />
      <path d="M94.9658 15.6464L96.3798 14.2324L99.4898 17.3444L98.0758 18.7584L94.9658 15.6464Z" fill="#76706E" />
      <path
        d="M103.2 9.00017C101.8 10.4002 100.6 11.2002 99.3999 10.0002C98.1999 8.80017 98.9999 7.60017 100.4 6.20017C101.8 4.80017 103 4.00017 104.2 5.20017C105.4 6.40017 104.6 7.60017 103.2 9.00017Z"
        fill="#76706E"
      />
      <path d="M101.7 8.73822L103.116 7.32422L106.226 10.4342L104.812 11.8482L101.7 8.73822Z" fill="#76706E" />
      <path
        d="M109.6 31.8002C98.7998 42.6002 95.7997 45.4002 89.1997 38.8002C82.5997 32.2002 85.3997 29.2002 96.1997 18.4002C110.6 4.00024 110.4 -0.199757 119.4 8.60024C128.2 17.6002 124 17.4002 109.6 31.8002Z"
        fill="#76706E"
      />
      <path
        d="M107.6 15.8001C109.036 15.8001 110.2 14.636 110.2 13.2001C110.2 11.7642 109.036 10.6001 107.6 10.6001C106.164 10.6001 105 11.7642 105 13.2001C105 14.636 106.164 15.8001 107.6 15.8001Z"
        fill="#B3ADAB"
      />
      <path
        d="M100.6 22.8001C102.036 22.8001 103.2 21.636 103.2 20.2001C103.2 18.7642 102.036 17.6001 100.6 17.6001C99.1641 17.6001 98 18.7642 98 20.2001C98 21.636 99.1641 22.8001 100.6 22.8001Z"
        fill="#B3ADAB"
      />
      <path
        d="M93.8002 29.5999C95.2361 29.5999 96.4002 28.4358 96.4002 26.9999C96.4002 25.564 95.2361 24.3999 93.8002 24.3999C92.3643 24.3999 91.2002 25.564 91.2002 26.9999C91.2002 28.4358 92.3643 29.5999 93.8002 29.5999Z"
        fill="#B3ADAB"
      />
      <path
        d="M114.8 23.0003C116.236 23.0003 117.4 21.8362 117.4 20.4003C117.4 18.9644 116.236 17.8003 114.8 17.8003C113.364 17.8003 112.2 18.9644 112.2 20.4003C112.2 21.8362 113.364 23.0003 114.8 23.0003Z"
        fill="#B3ADAB"
      />
      <path
        d="M109.8 29.2001C108.8 30.2001 107.2 30.2001 106.2 29.2001C105.2 28.2001 105.2 26.6001 106.2 25.6001C107.2 24.6001 108.8 24.6001 109.8 25.6001C110.8 26.6001 110.8 28.2001 109.8 29.2001Z"
        fill="#B3ADAB"
      />
      <path
        d="M101 36.8001C102.436 36.8001 103.6 35.636 103.6 34.2001C103.6 32.7642 102.436 31.6001 101 31.6001C99.5644 31.6001 98.4004 32.7642 98.4004 34.2001C98.4004 35.636 99.5644 36.8001 101 36.8001Z"
        fill="#B3ADAB"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
