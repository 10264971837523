<template>
  <div class="ks-hobbie__icons-item">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.342 14.448L35.934 21.2622L30.6007 23.2622L25.4896 28.3733L24.1562 32.1511L35.7118 30.3733C35.7118 30.3733 43.934 53.7066 57.2674 59.2622C57.2674 59.2622 54.3785 70.5955 60.1562 75.04L61.4896 95.2622L56.3785 113.929L52.8229 119.634L57.0665 119.687C57.6128 119.694 58.1533 119.574 58.646 119.338C59.1387 119.102 59.5703 118.755 59.9074 118.325L62.1029 115.527C62.4303 115.109 62.6599 114.623 62.7749 114.105L66.7376 96.3111C66.7945 96.0569 66.8229 95.7991 66.8229 95.5378V76.1511L69.4896 79.04L75.7118 95.4844L78.8229 113.929L76.4531 119.559L79.87 119.374C80.4964 119.34 81.1027 119.141 81.6272 118.797C82.1518 118.453 82.5759 117.976 82.8567 117.415L84.2736 114.585C84.6295 113.872 84.7359 113.061 84.5758 112.281L81.07 95.1644C81.0534 95.082 81.0338 95.0001 81.0114 94.9191L76.3785 78.3733L81.934 76.8178L87.2674 74.5955C87.2674 74.5955 93.7118 87.7066 98.8229 88.5955L100.601 112.596L98.8229 119.484L100.842 119.42C101.607 119.396 102.344 119.126 102.943 118.65C103.542 118.173 103.971 117.517 104.167 116.777L105.031 113.506C105.138 113.1 105.172 112.679 105.132 112.261L102.866 88.3769C102.837 88.08 102.771 87.7879 102.67 87.5075L100.821 82.3733L97.934 67.2622L95.934 60.8178L89.4896 55.2622L77.0451 54.3733L66.6007 47.4844C66.6007 47.4844 52.0816 46.0782 41.8594 23.4115C41.8594 23.4115 41.118 12.8924 35.3402 14.448H35.342Z"
        fill="#D4CECC"
      />
      <path
        d="M53.1127 117.813L56.558 113.703L61.4469 95.5929L60.3358 75.1485C54.1136 68.0374 57.7847 59.7476 57.7847 59.7476C40.2291 50.4142 36.3358 30.592 36.3358 30.592L24.8869 32.3396C23.3171 32.3396 22.5225 30.4498 23.6194 29.328L28.558 25.2587L30.6682 22.2596L35.3349 21.1485L35.2247 16.0374"
        stroke="#B3ADAB"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.8915 71.5911C65.8915 71.5911 65.6693 76.9244 70.1137 78.592L76.0017 95.3706L79.1128 113.481L76.7804 117.815"
        stroke="#B3ADAB"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.8994 77.0365H82.6665C82.6665 77.0365 88.1119 76.0374 88.6665 70.0374"
        stroke="#B3ADAB"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M87.335 73.8933C87.335 73.8933 89.0025 82.3698 98.6683 88.4818L100.114 112.997L99.2247 117.815"
        stroke="#B3ADAB"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M103.447 84.3696C98.8761 81.127 97.8752 75.0932 97.7206 71.4007C97.6239 68.8812 97.2179 66.383 96.5117 63.9625L96.3339 63.3705C92.965 53.7705 80.933 55.8861 75.0006 53.4807C70.4708 51.6443 66.4441 46.5012 65.8895 46.5012C51.5126 46.5012 40.6895 24.6345 40.6895 24.6345"
        stroke="#B3ADAB"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
