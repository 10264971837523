<template>
  <div class="ks-hobbie__icons-item">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.57376 52.8213L7.55953 52.8142C7.55953 52.8142 0.26709 96.9635 0.26709 103.328C0.26709 109.813 5.0422 115.068 10.9338 115.068C13.3515 115.068 15.556 114.151 17.3444 112.658L53.4902 80.8782L7.57376 52.8213ZM120.249 52.8213L120.264 52.8142C120.264 52.8142 127.556 96.9635 127.556 103.328C127.556 109.813 122.781 115.068 116.889 115.068C114.472 115.068 112.267 114.151 110.479 112.658L74.3329 80.8782L120.249 52.8213Z"
        fill="#D4CECC"
      />
      <path
        d="M92.3555 85.5148C109.047 85.5148 122.578 71.9838 122.578 55.2925C122.578 38.6013 109.047 25.0703 92.3555 25.0703C75.6643 25.0703 62.1333 38.6013 62.1333 55.2925C62.1333 71.9838 75.6643 85.5148 92.3555 85.5148Z"
        fill="#B3ADAB"
      />
      <path
        d="M35.4669 85.5148C52.1581 85.5148 65.6891 71.9838 65.6891 55.2925C65.6891 38.6013 52.1581 25.0703 35.4669 25.0703C18.7756 25.0703 5.24463 38.6013 5.24463 55.2925C5.24463 71.9838 18.7756 85.5148 35.4669 85.5148Z"
        fill="#B3ADAB"
      />
      <path d="M35.4668 25.0703H92.3557V85.0525H35.4668V25.0703Z" fill="#B3ADAB" />
      <path
        d="M91.5018 68.3019C94.6496 68.3019 97.2014 65.7502 97.2014 62.6024C97.2014 59.4546 94.6496 56.9028 91.5018 56.9028C88.354 56.9028 85.8022 59.4546 85.8022 62.6024C85.8022 65.7502 88.354 68.3019 91.5018 68.3019Z"
        fill="#76706E"
      />
      <path
        d="M46.4892 100.626C56.3076 100.626 64.267 92.6665 64.267 82.8481C64.267 73.0297 56.3076 65.0703 46.4892 65.0703C36.6708 65.0703 28.7114 73.0297 28.7114 82.8481C28.7114 92.6665 36.6708 100.626 46.4892 100.626Z"
        fill="#C8C2C0"
      />
      <path
        d="M81.3334 100.626C91.1518 100.626 99.1112 92.6665 99.1112 82.8481C99.1112 73.0297 91.1518 65.0703 81.3334 65.0703C71.515 65.0703 63.5557 73.0297 63.5557 82.8481C63.5557 92.6665 71.515 100.626 81.3334 100.626Z"
        fill="#C8C2C0"
      />
      <path
        d="M81.3332 93.5147C87.2242 93.5147 91.9998 88.7391 91.9998 82.8481C91.9998 76.957 87.2242 72.1814 81.3332 72.1814C75.4421 72.1814 70.6665 76.957 70.6665 82.8481C70.6665 88.7391 75.4421 93.5147 81.3332 93.5147Z"
        fill="#76706E"
      />
      <path
        d="M46.4894 93.5147C52.3805 93.5147 57.1561 88.7391 57.1561 82.8481C57.1561 76.957 52.3805 72.1814 46.4894 72.1814C40.5984 72.1814 35.8228 76.957 35.8228 82.8481C35.8228 88.7391 40.5984 93.5147 46.4894 93.5147Z"
        fill="#76706E"
      />
      <path
        d="M91.5018 45.2836C94.6496 45.2836 97.2014 42.7319 97.2014 39.5841C97.2014 36.4363 94.6496 33.8845 91.5018 33.8845C88.354 33.8845 85.8022 36.4363 85.8022 39.5841C85.8022 42.7319 88.354 45.2836 91.5018 45.2836Z"
        fill="#76706E"
      />
      <path
        d="M79.993 56.7932C83.1408 56.7932 85.6926 54.2414 85.6926 51.0936C85.6926 47.9458 83.1408 45.394 79.993 45.394C76.8452 45.394 74.2935 47.9458 74.2935 51.0936C74.2935 54.2414 76.8452 56.7932 79.993 56.7932Z"
        fill="#76706E"
      />
      <path
        d="M103.012 56.7932C106.16 56.7932 108.712 54.2414 108.712 51.0936C108.712 47.9458 106.16 45.394 103.012 45.394C99.8643 45.394 97.3125 47.9458 97.3125 51.0936C97.3125 54.2414 99.8643 56.7932 103.012 56.7932Z"
        fill="#76706E"
      />
      <path
        d="M39.6375 44.4945V36.786C39.6375 36.3098 39.4484 35.8531 39.1116 35.5164C38.7749 35.1797 38.3182 34.9905 37.842 34.9905H32.3024C31.8262 34.9905 31.3695 35.1797 31.0328 35.5164C30.6961 35.8531 30.5069 36.3098 30.5069 36.786V44.4945L35.0722 49.0634L39.6375 44.4945ZM30.5033 57.4011V65.1096C30.5033 66.1016 31.3069 66.9051 32.2989 66.9051H37.8384C38.3146 66.9051 38.7714 66.716 39.1081 66.3792C39.4448 66.0425 39.634 65.5858 39.634 65.1096V57.4011L35.0687 52.8323L30.5033 57.4011ZM49.2375 46.3789H41.5291L36.9602 50.9478L41.5291 55.5167H49.2375C49.7135 55.5158 50.1696 55.3263 50.5062 54.9898C50.8427 54.6532 51.0322 54.1971 51.0331 53.7211V48.178C51.0331 47.7015 50.844 47.2444 50.5074 46.9071C50.1708 46.5698 49.7141 46.3799 49.2375 46.3789ZM28.6189 46.3789H20.9069C20.431 46.3799 19.9748 46.5693 19.6383 46.9059C19.3017 47.2424 19.1123 47.6986 19.1113 48.1745V53.714C19.1113 54.706 19.9149 55.5096 20.9069 55.5096H28.6153L33.1842 50.9407L28.6189 46.3789Z"
        fill="#76706E"
      />
    </svg>
  </div>
</template>

<script>
export default {}
</script>
