<template>
  <div class="ks-hobbie__icons-item">
    <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M121.639 89.7014C135.975 58.0039 121.9 20.6869 90.2021 6.35159C58.5046 -7.9837 21.1876 6.09116 6.85235 37.7887C-7.48295 69.4862 6.59192 106.803 38.2894 121.138C69.987 135.474 107.304 121.399 121.639 89.7014Z"
          fill="#D4CECC"
        />
        <path
          d="M91.26 66.4599C91.05 66.1799 90.78 65.9399 90.43 65.7799C90.06 65.6099 89.67 65.5699 89.3 65.6099C79.37 61.4399 71.09 60.2999 67.97 60.0099C64.82 53.5699 62.63 47.1999 61.43 40.9199C70.12 40.4199 102.73 40.5899 127.09 67.9099C127.23 65.8499 127.26 63.7899 127.19 61.7399C101.7 35.9899 69.26 36.1299 60.78 36.6599C59.92 29.5099 60.34 22.4899 62.06 15.6399C70.14 15.3599 97.37 15.9899 120.14 34.7499C118.363 31.3239 116.278 28.0671 113.91 25.0199C93.5 12.3599 71.99 11.1899 63.33 11.3199C64.87 6.79989 66.73 3.26989 68.18 0.889893C66.54 0.789893 64.9 0.749893 63.27 0.769893C61.0641 4.81485 59.3486 9.10852 58.16 13.5599C56.44 19.9599 55.31 28.6099 56.82 38.9299C56.82 39.0399 56.82 39.1499 56.83 39.2599C56.85 39.3899 56.88 39.5199 56.92 39.6399C57.98 46.3899 60.17 53.8399 64.08 61.8399C62.25 64.2399 57.04 70.6199 48.97 76.9399C44.24 70.2199 26.78 41.5999 42.53 4.62989C40.65 5.31989 38.81 6.09989 37.01 6.95989C23.53 43.6799 40.8 72.5699 45.54 79.4799C40.3 83.1799 34.07 86.6699 26.97 89.1199C22.79 81.6899 8.58998 52.5399 20.75 18.1799C18.33 20.4799 16.09 22.9999 14.04 25.7099C6.65998 56.5499 18.25 82.0499 22.78 90.4099C18.24 91.6399 13.38 92.3799 8.22998 92.5299C8.96998 93.9699 9.74998 95.3899 10.6 96.7699C15.93 96.4499 20.93 95.5199 25.58 94.1499C26.19 94.3599 26.88 94.2999 27.45 93.9199C27.67 93.7699 27.85 93.5899 28 93.3899C50.43 85.9099 64.35 68.6899 67.62 64.2799C70.3 64.5299 77.59 65.4999 86.46 69.0599C81.89 77.6099 63.23 108.24 28.01 115.27C29.65 116.42 31.35 117.5 33.13 118.51C67.69 109.55 85.95 79.1799 90.4 70.7799C95.62 73.2299 101.2 76.5699 106.61 81.1799C103.74 87.1299 88.68 115.47 58.45 126.47C61.94 126.79 65.43 126.83 68.88 126.57C93.1 114.08 106.13 91.5799 109.91 84.1799C113.271 87.4328 116.318 90.9952 119.01 94.8199C119.798 93.4205 120.535 91.9929 121.22 90.5399C112.05 78.2499 101.01 70.8699 91.26 66.4599Z"
          fill="#B3ADAB"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {}
</script>
