<template>
  <div class="ks-heading">
    <h1 class="ks-heading__name">
      Косоплечев
      <br />
      Алексей Владимирович
    </h1>
    <h2 class="ks-heading__position">Менеджер & Организатор</h2>
  </div>
</template>

<script>
export default {}
</script>

<style>
.ks-heading {
  width: 100%;
  padding-bottom: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 216px);
}
.ks-heading__name {
  font-size: 7rem;
  text-align: center;
}
.ks-heading__position {
  font-size: 2rem;
  margin: 2rem 0 0 0;
}
@media screen and (max-width: 800px) {
  .ks-heading__name {
    font-size: 5rem;
  }
}
</style>
